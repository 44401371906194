import request from '@/utils/request'


// 获取验证码
export function getCodeImg() {
  return request({
    url: '/sysmenu/loginKey',
    method: 'post'
  })
}


// 登录方法
export function login(useraccount, password, keycode, times) {
  const data = {
    useraccount,
    password,
    keycode,
    times
  }
  return request({
    url: '/sysmenu/loginUser',
    method: 'post',
    params: data
  })
}


//修改密码
export function updatePwd(data) {
  return request({
    url: '/sysmenu/updatePassWord',
    method: 'post',
    params: data
  })
}

//获取当前登录用户的菜单（左侧菜单）
export function curUserMenu(data) {
  return request({
    url: '/common/getUserMenu',
    method: 'post',
    params: data
  })
}