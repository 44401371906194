import Vue from 'vue';
import Router from 'vue-router';
import {
	Message
} from 'element-ui'
import global_ from '../utils/config'
import layout from '@/view/IndexContent'
import { getToken } from '@/utils/auth'

Vue.use(Router);

// 解决报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};



const routes = [{
		path: '',
		redirect: '/index'
	},
	{
		path: '/login',
		component: () => import('@/view/login'),
		name: 'login',
		meta: {
			title: '登录', // 页签标题
		}
	},
	{
		path: '/monitor',
		component: () => import('@/view/monitor'),
		name: 'monitor',
		meta: {
			title: '大屏', // 页签标题
		}
	},
	{
		path: '/daping',
		component: () => import('@/view/daping'),
		name: 'daping',
		meta: {
			title: '大屏', // 页签标题
		}
	},
	{
		path: '/index',
		component: layout,
		children: [{
			path: '',
			component: () => import('@/view/Index'),
			name: 'index',
			meta: {
				title: '首页'
			}
		}]
	},
	{
		path: '/banner',
		component: layout,
		children: [{
			path: 'banner',
			component: () => import('@/view/banner/index'),
			name: 'banner',
			meta: {
				title: 'banner管理'
			}
		}]
	},
	{
		path: '/system',
		component: layout,
		children: [{
			path: 'account',
			component: () => import('@/view/system/account'),
			name: 'account',
			meta: {
				title: '用户管理'
			}
		},{
			path: 'departments',
			component: () => import('@/view/system/departments'),
			name: 'departments',
			meta: {
				title: '第三方管理'
			}
		},{
			path: 'wxtemplate',
			component: () => import('@/view/wxTemplate'),
			name: 'wxtemplate',
			meta: {
				title: '微信消息模版'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'kehulist',
			component: () => import('@/view/kehulist'),
			name: 'kehulist',
			meta: {
				title: '客户管理'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'personintegral',
			component: () => import('@/view/personPointOprate'),
			name: 'personintegral',
			meta: {
				title: '个人积分操作'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: '/examinePoint',
			component: () => import('@/view/examinePoint'),
			name: 'examinePoint',
			meta: {
				title: '一级审核'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: '/examinePointTwice',
			component: () => import('@/view/examinePointTwice'),
			name: 'examinePointTwice',
			meta: {
				title: '二级审核'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: '/daishiminglist',
			component: () => import('@/view/daishiminglist'),
			name: 'daishiminglist',
			meta: {
				title: '认证审核'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: '/yishiminglist',
			component: () => import('@/view/yishiminglist'),
			name: 'yishiminglist',
			meta: {
				title: '认证查询'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: '/placemanage',
			component: () => import('@/view/placesManage/indexNew.vue'),
			name: 'placemanage',
			meta: {
				title: '场地管理'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: '/sitetime',
			component: () => import('@/view/siteTime/index'),
			name: 'sitetime',
			meta: {
				title: '场地时段'
			}
		},
		{
			path: '/orderAudit',
			component: () => import('@/view/siteTime/orderAudit'),
			name: 'orderAudit',
			meta: {
				title: '场地预约审核'
			}
		}]
	},
	{
		path: '/blacklist',
		component: layout,
		children: [{
			path: '',
			component: () => import('@/view/blacklist'),
			name: 'blacklist',
			meta: {
				title: '黑名单管理'
			}
		}]
	},
	{
		path: '/refundauditing',
		component: layout,
		children: [{
			path: '',
			component: () => import('@/view/refundAuditing'),
			name: 'refundauditing',
			meta: {
				title: '退款审核'
			}
		}]
	},
	{
		path: '/settlement',
		component: layout,
		children: [{
			path: '',
			component: () => import('@/view/settlement'),
			name: 'settlement',
			meta: {
				title: '商户结算'
			}
		}]
	},
	{
		path: '/shequ',
		component: layout,
		children: [{
			path: 'shequ',
			component: () => import('@/view/shequ/shequ'),
			name: 'shequ',
			meta: {
				title: '社区管理'
			}
		},{
			path: 'xiaoqu',
			component: () => import('@/view/shequ/xiaoqu'),
			name: 'xiaoqu',
			meta: {
				title: '小区管理'
			}
		}]
	},
	{
		path: '/activity',
		component: layout,
		children: [{
			path: 'activitylist',
			component: () => import('@/view/activity/activityList'),
			name: 'activitylist',
			meta: {
				title: '活动列表'
			}
		},{
			path: 'activityshenhe',
			component: () => import('@/view/activity/activityShenhe'),
			name: 'activityshenhe',
			meta: {
				title: '活动审核'
			}
		},{
			path: 'chooseactivity',
			component: () => import('@/view/activity/addActivity/chooseActivityItem'),
			name: 'chooseactivity',
			meta: {
				title: '活动新增'
			}
		},{
			path: 'joinlist',
			component: () => import('@/view/activity/allinfosList'),
			name: 'joinlist',
			meta: {
				title: '参与列表'
			}
		},{
			path: 'addactivity',
			component: () => import('@/view/activity/addActivity'),
			name: 'addactivity',
			meta: {
				title: '活动新增'
			}
		},{
			path: 'doorcheck',
			component: () => import('@/view/entranceGuard/check'),
			name: 'doorcheck',
			meta: {
				title: '门禁审核'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'renwulist',
			component: () => import('@/view/haverenwu'),
			name: 'renwulist',
			meta: {
				title: '做任务赚积分'
			}
		},
		{
			path: 'taskcheck',
			component: () => import('@/view/task/taskReview.vue'),
			name: 'taskcheck',
			meta: {
				title: '任务审核'
			}
		},{
			path: 'wanchenglist',
			component: () => import('@/view/wanchengList'),
			name: 'wanchenglist',
			meta: {
				title: '任务完成情况'
			}
		}]
	},
	{
		path: '/entranceguard',
		component: layout,
		children: [{
			path: 'eqmanage',
			component: () => import('@/view/entranceGuard/equipmentMange'),
			name: 'eqmanage',
			meta: {
				title: '设备管理'
			}
		},{
			path: 'eqlog',
			component: () => import('@/view/entranceGuard/equipmentLog'),
			name: 'eqlog',
			meta: {
				title: '设备日志'
			}
		},{
			path: 'eqmonitor',
			component: () => import('@/view/entranceGuard/equipmentMonitor'),
			name: 'eqmonitor',
			meta: {
				title: '设备监控'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'itemcategory',
			component: () => import('@/view/itemCategory'),
			name: 'itemcategory',
			meta: {
				title: '商品类目'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'goodsmanage',
			component: () => import('@/view/goodsManage'),
			name: 'goodsmanage',
			meta: {
				title: '商品管理'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'goodsshenhe',
			component: () => import('@/view/goodsManage/shenhe.vue'),
			name: 'goodsshenhe',
			meta: {
				title: '商品审核'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'promotion',
			component: () => import('@/view/promotion'),
			name: 'promotion',
			meta: {
				title: '限时促销'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'ordermanage',
			component: () => import('@/view/orderManage'),
			name: 'ordermanage',
			meta: {
				title: '订单管理'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'redeemcode',
			component: () => import('@/view/redeemCode'),
			name: 'redeemcode',
			meta: {
				title: '兑换码管理'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'scanpayrecord',
			component: () => import('@/view/scanpayRecord'),
			name: 'scanpayrecord',
			meta: {
				title: '扫码支付记录'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'merchantmanage',
			component: () => import('@/view/merchantManage'),
			name: 'merchantmanage',
			meta: {
				title: '商户管理'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'shoptypemanage',
			component: () => import('@/view/goodsManage/shopTypeManage'),
			name: 'shoptypemanage',
			meta: {
				title: '商户类型管理'
			}
		}]
	},
	
	{
		path: '/',
		component: layout,
		children: [{
			path: 'noticemanage',
			component: () => import('@/view/noticeManage'),
			name: 'noticemanage',
			meta: {
				title: '公告管理'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'feedback',
			component: () => import('@/view/feedback'),
			name: 'feedback',
			meta: {
				title: '反馈与建议'
			}
		}]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'integral',
			component: () => import('@/view/system/integral'),
			name: 'integral',
			meta: {
				title: '批量积分操作'
			}
		}]
	},
	
	{
		path: '/showlove',
		component: layout,
		children: [
			{
				path: 'slmember',
				component: () => import('@/view/showLove/member/index'),
				name: 'slmember',
				meta: {
					title: '社员管理'
				}
			},
			{
				path: 'slactivity',
				component: () => import('@/view/showLove/activity/index'),
				name: 'slactivity',
				meta: {
					title: '社员服务管理'
				}
			},
			{
				path: 'checkactivity',
				component: () => import('@/view/showLove/activity/checkactivity'),
				name: 'checkactivity',
				meta: {
					title: '社员服务审核'
				}
			}
		]
	},
	{
		path: '/',
		component: layout,
		children: [{
			path: 'volservicetype',
			component: () => import('@/view/volServiceType'),
			name: 'volservicetype',
			meta: {
				title: '社员服务项目管理'
			}
		}]
	},
	{
		path: '/wenjuanhtml',
		component: () => import('@/view/wenjuanHtml'),
		name: 'wenjuanhtml',
		meta: {
			title: '问卷内容'
		}
	},
	{
		path: '/report',
		component: layout,
		children: [{
			path: 'merchantReport',
			component: () => import('@/view/reportStatistics/merchantReport'),
			name: 'merchantReport',
			meta: {
				title: '商户积分报表'
			}
		},{
			path: 'pointsRanking',
			component: () => import('@/view/reportStatistics/pointsRanking'),
			name: 'pointsRanking',
			meta: {
				title: '积分排行报表'
			}
		},{
			path: 'partyMemberRanking',
			component: () => import('@/view/reportStatistics/partyMemberRanking'),
			name: 'partyMemberRanking',
			meta: {
				title: '党员排行'
			}
		},{
			path: 'healthMemberRanking',
			component: () => import('@/view/reportStatistics/healthMemberRanking'),
			name: 'healthMemberRanking',
			meta: {
				title: '特困群体排行'
			}
		},{
			path: 'promotionSituation',
			component: () => import('@/view/reportStatistics/promotionSituation'),
			name: 'promotionSituation',
			meta: {
				title: '社区推进情况'
			}
		},{
			path: 'systemReport',
			component: () => import('@/view/reportStatistics/systemReport'),
			name: 'systemReport',
			meta: {
				title: '系统积分报表'
			}
		},{
			path: 'communityVolReport',
			component: () => import('@/view/reportStatistics/communityVolReport'),
			name: 'communityVolReport',
			meta: {
				title: '社区社员概况'
			}
		},{
			path: 'volunteerSummary',
			component: () => import('@/view/reportStatistics/volunteerSummary'),
			name: 'volunteerSummary',
			meta: {
				title: '社员相关信息'
			}
		},{
			path: 'cpcVolunteerSummary',
			component: () => import('@/view/reportStatistics/cpcVolunteerSummary'),
			name: 'cpcVolunteerSummary',
			meta: {
				title: '社区党员社员情况'
			}
		},{
			path: 'merchantsReport',
			component: () => import('@/view/reportStatistics/merchantsReport'),
			name: 'merchantsReport',
			meta: {
				title: '商户消费概况'
			}
		},{
			path: 'recommendMerchantReport',
			component: () => import('@/view/reportStatistics/recommendMerchantReport'),
			name: 'recommendMerchantReport',
			meta: {
				title: '推荐商户概况'
			}
		}]
	},
	{
		path: '*', //!* 是一个通配符
		redirect: '/index'
	}
]



const router = new Router({
	mode: 'hash',
	scrollBehavior: () => ({
		y: 0
	}),
	routes
});

//全局路由守卫
router.beforeEach((to, from, next) => {
	if(to.path!='/wenjuanhtml'){
		const title = to.meta && to.meta.title;
		if (title) {
			document.title = global_.system_name + '——' + title;
		}
		let username = window.localStorage.getItem('username');
		let adminId = window.localStorage.getItem('userid');
		if (getToken() === undefined && to.path != '/login') {
			Message({
				message: '请登录系统',
				type: 'warning'
			});
			next({
				path: "/login"
			});
		} else if (getToken() && username && adminId && to.path == '/login') {
			Message({
				message: '您已登录系统',
				type: 'success'
			});
			next({
				path: '/index'
			})
		} else {
			next();
		}
	}else{
		next();
	}

});



export default router