import request from '@/utils/request'

// 当天报名活动用户
export function queryTodayJoinRecords(data) {
  return request({
    url: '/MA_actActivity/queryTodayJoinRecords',
    method: 'post',
    params: data
  })
}

// 报名社员活动未审核用户
export function queryApplyNotAuditRecords(data) {
  return request({
    url: '/MA_volApply/queryNotAuditRecords',
    method: 'post',
    params: data
  })
}

// 社员活动反馈未审核记录
export function queryVolFeedbackNotAuditRecords(data) {
  return request({
    url: '/MA_volFeedback/queryNotAuditRecords',
    method: 'post',
    params: data
  })
}

// 未审核的社员申请记录
export function queryVolNotAuditRecords(data) {
  return request({
    url: '/MA_volunteer/queryNotAuditRecords',
    method: 'post',
    params: data
  })
}