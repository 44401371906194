<template>
	<el-container class="contain">
		<el-header class="header">
			<!-- <img class="logo" src="../assets/imgs/index_logo.png" alt=""> -->
			<!-- <img class="tit" src="../assets/imgs/index_title.png" alt=""> -->
      <!-- 启新共同富裕社 -->
      <div class="tit">{{titleName}}</div>
			<span class="collapse" @click="isCollapse = !isCollapse">
				<i class="iconfont icon-zhankai" v-if="isCollapse"></i>
				<i class="iconfont icon-shousuo" v-else></i>
			</span>
      <div class="tips-box" v-if="noticeList.length > 0">
        <van-notice-bar left-icon="volume-o" :scrollable="false">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
          >
            <van-swipe-item class="item" v-for="item in noticeList" :key="item.type" @click="gotoPage(item.type)">{{item.title}}： {{item.num}}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>

			<div class="name" @mouseenter="menuFlag = true" @mouseleave="menuFlag = false">
				<div class="username">
					<div class="user"><i class="el-icon-user-solid"></i></div>
					<div>
						<span>{{ username || "松果科技" }}，您好 </span>
						<i class="el-icon-caret-bottom"></i>
					</div>
				</div>

				<div class="menus" :class="menuFlag ? 'show' : ''">
					<ul>
						<li @click="editPassword" class="password"><i class="el-icon-lock"></i>修改密码</li>
					</ul>
				</div>
			</div>

			<div class="quit" @click="quit()">
				<i class="iconfont icon-tuichu"></i>
			</div>
		</el-header>

		<el-container class="main">
			<el-aside class="side" :class="isCollapse==false ? 'addwidth':'removewidth'">
				<el-scrollbar style="height: 100%">
					<!-- <el-menu
            :default-active="$route.path"
            :router="isRouter"
            background-color="#002664"
            text-color="#fff"
            active-text-color="#fff"
            :unique-opened="true"
            :collapse="isCollapse"
          >
            <menutree :data="menu_data"></menutree>
          </el-menu> -->
					<el-menu :default-active="$route.path" :router="isRouter" background-color="#002664"
						text-color="#fff" active-text-color="#fff" :unique-opened="true" :collapse="isCollapse">
						<!--动态生成sideItem-->
						<template v-for="(item, parentIndex) in menu_data">
							<SideNav :item="item" :index="parentIndex" :key="parentIndex"></SideNav>
						</template>
					</el-menu>

				</el-scrollbar>

			</el-aside>

			<el-main class="mainbox">
				<router-tab class="default" />
			</el-main>
		</el-container>

		<!-- 修改密码-->
		<el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="400px" :before-close="cancelbtn" :close-on-click-modal="false">
			<el-form :model="form" class="passworddialog">
				<el-form-item label="新密码" :label-width="formLabelWidth">
					<el-input v-model="form.password" type="password" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="确认新密码" :label-width="formLabelWidth">
					<el-input v-model="form.sure_password" type="password" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancelbtn">取 消</el-button>
				<el-button type="primary" @click="surebtn_changePw">确认修改</el-button>
			</div>
		</el-dialog>
	</el-container>
</template>

<script>
import { removeToken } from '@/utils/auth'
import SideNav from "@/components/common/SideNav";
import { updatePwd,curUserMenu } from "@/api/login"
import { queryTodayJoinRecords, queryApplyNotAuditRecords, queryVolFeedbackNotAuditRecords, queryVolNotAuditRecords } from "@/api/notice"
export default {
  name: "IndexContent",
  components: {
    SideNav
  },
  data() {
    return {
      isRouter: true,
      username: this.GLOBAL.username(),
      menu_data: [],
      menuFlag: false,
      form: {
        password: "",
        sure_password: "",
      },
      titleName: '',
      formLabelWidth: "120px",
      dialogFormVisible: false,
      isCollapse: false,
      noticeList: [],
    };
  },
  mounted() {
    this.getNoticeList();
    setInterval(() => {
      this.getNoticeList();
    }, 30000);
    this.getList();
    if(this.GLOBAL.level() == -1) {
      this.titleName = '爱社区 · 共同富裕社'
    } else {
      this.titleName = this.GLOBAL.levelname() + '分社'
    }
  },
  methods: {
    getNoticeList() {
      let that = this;
      that.noticeList = [];
      //当天报名活动用户
      queryTodayJoinRecords().then(res => {
        if(res.result == 200) {
          if(res.detail.length > 0) {
            that.noticeList.push({
              title: '当天报名活动未审核用户人数',
              num: res.detail.length,
              type: 1
            })
          }
        }
      })
      // 报名社员活动未审核用户
      queryApplyNotAuditRecords().then(res => {
        if(res.result == 200) {
          if(res.detail.length > 0) {
            that.noticeList.push({
              title: '报名社员活动未审核用户人数',
              num: res.detail.length,
              type: 2
            })
          }
        }
      })
      // 社员活动反馈未审核记录
      queryVolFeedbackNotAuditRecords().then(res => {
        if(res.result == 200) {
          if(res.detail.length > 0) {
            that.noticeList.push({
              title: '社员活动反馈未审核记录',
              num: res.detail.length,
              type: 3
            })
          }
        }
      })
      // 未审核的社员申请记录
      queryVolNotAuditRecords().then(res => {
        if(res.result == 200) {
          if(res.detail.length > 0) {
            that.noticeList.push({
              title: '社员申请未审核记录',
              num: res.detail.length,
              type: 4
            })
          }
        }
      })
    },
    gotoPage(type) {
      if(type == 1) {
        // 审核报名活动用户
        this.$router.push({
          path: '/activity/activitylist'
        })
      } else if(type == 2) {
        // 审核报名社员活动
        this.$router.push({
          path: '/showlove/slactivity'
        })
      } else if(type == 3) {
        // 审核社员活动反馈
        this.$router.push({
          path: '/showlove/slactivity'
        })
      } else if(type == 4) {
        // 审核社员
        this.$router.push({
          path: '/showlove/slmember'
        })
      }
    },
    //修改密码
    editPassword() {
      this.form = {
        password: "",
        sure_password: "",
      };
      this.dialogFormVisible = true;
    },
    //取消修改
    cancelbtn() {
      this.dialogFormVisible = false;
      this.form = {
        password: "",
        sure_password: "",
      };
    },
    //确认修改密码
    surebtn_changePw() {
      if (this.form.password.trim() == "") {
        this.$message({
          message: "请输入新密码",
          type: "warning",
        });
        return false;
      }
	  if(this.form.password.trim()){
		let reg = /^(?![A-Za-z0-9]+$)(?![A-Za-z\W]+$)(?![0-9\W]+$)(?!.*(012|123|234|345|456|567|678|789|987|876|765|654|543|432|321|210))[a-zA-Z][a-zA-Z0-9\W]{7,}$/
		if (!reg.test(this.form.password.trim())) {
			this.$message.warning('以字母开头，至少包含字母、数字、特殊字符并且不能连续出现3个大小连续或相同数字(如：456、654、888)的8位数及以上的密码');
			return false;
	    }
	  }
      if (this.form.sure_password.trim() == "") {
        this.$message({
          message: "请输入确认新密码",
          type: "warning",
        });
        return false;
      }
      if (this.form.password.trim() != this.form.sure_password.trim()) {
        this.$message({
          message: "两次输入的密码不一致，请确认一致后再提交",
          type: "warning",
        });
        return false;
      }

      updatePwd({
        userid: this.GLOBAL.adminId(),
        password: this.form.password,
        passwordtwo: this.form.sure_password,
      }).then(res=>{
        if (res.result == 200) {
            this.$message({
              message: "密码修改成功",
              type: "success",
            });
            this.cancelbtn();
          } else {
            this.$message({
              message: res.description,
              type: "warning",
            });
          }
      })
    },
    //退出系统
    quit() {
      let that = this;
      that
        .$confirm("确认要退出吗?", "退出确认", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$message({
            message: "已成功退出",
            type: "success",
          });
          localStorage.clear();
          removeToken();
          that.$router.push({
            name: "login",
          });
        })
        .catch(() => {});
    },
    //获取菜单列表
    getList() {
      curUserMenu({
        userid: this.GLOBAL.adminId()
      }).then(res=>{
        if(res.result == 200){
          this.menu_data = res.detail.usermenu;
          /* this.menu_data = [
            {
              menuname: "首页",
              menupic: "el-icon-s-home",
              menuurl: "/index",
            },            
            {
              menuname: "签订协议",
              menupic: "el-icon-edit-outline",
              menuurl: null,
              childrenmenu: [
                {
                  menuname: "接收登记",
                  menupic: null,
                  menuurl: "/registration",
                }
              ],
            },
            {
              menuname: "系统设置",
              menupic: "el-icon-setting",
              menuurl: null,
              childrenmenu: [
                {
                  menuname: "账号管理",
                  menupic: null,
                  menuurl: "/system/account",
                  
                }
              ],
            },
            {
              menuname: "定期尿检",
              menupic: "el-icon-setting",
              menuurl: null,
              childrenmenu: [
                {
                  menuname: "常规/抽查尿检管理",
                  menupic: null,
                  menuurl: "/urinalysis",
                }
              ],
            },
            {
              menuname: "服药管理",
              menupic: "el-icon-setting",
              menuurl: null,
              childrenmenu: [
                {
                  menuname: "服用美沙酮管理",
                  menupic: null,
                  menuurl: "/medicine",
                }
              ],
            },
            {
              menuname: "请假管理",
              menupic: "el-icon-setting",
              menuurl: null,
              childrenmenu: [
                {
                  menuname: "请假外出登记",
                  menupic: null,
                  menuurl: "/leave",
                }
              ],
            }
          ] */
        }else{
          console.log(1)
        }
      })
      
    },
  },
};
</script>

<style lang="scss">
$headHeight: 85px;
 
.addwidth{
  width: 230px !important;
  // span{
  //   display: initial;
  // }
}
.removewidth{
  width: 64px !important;
  // span{
  //   display: none  !important;
  // }
}


.router-tab {
  width: 100%;
  height: 100%;
}

.contain {
  height: 100%;
}

.header {
  line-height: $headHeight;
  height: $headHeight !important;
  position: relative;
  background: url(../assets/imgs/headerbg.jpg) repeat;
  color: #fff;
  display: flex;
  align-items: center;
  // overflow: hidden;

  .logo{
    width: 34px;
    height: auto;
  }
  .tit{
    // width: 160px;
    height: auto;
    margin-left: 5px;
    display: inline-block;
    font-size: 22px;
    font-weight: 600;
  }
  .collapse{
    margin-left: 20px;
    cursor: pointer;
    opacity: 0.8;
    transition: 0.3s opacity;
    &:hover{
      opacity: 1;
    }
    i{
      font-size: 24px;
      color: #87b8e2;
    }
  }

  img{
    margin-top: -5px;
  }
}
.tips-box {
  margin-left: 100px;
  font-size: 16px;
  color: #E6A23C;
  width: 60%;
  height: 40px;
  overflow: hidden;
  .item {
    cursor: pointer;
  }
}


.header .quit {
  position: absolute;
  right: 40px;
  top: 0;
  cursor: pointer;
  transition: 0.3s color;
}

.header .quit i {
  color: #4389df;
  margin-right: 5px;
  font-size: 22px;
}

.header .quit:hover,
.header .quit:hover i {
  color: #4c96f0;
}

.header .name {
  position: absolute;
  right: 110px;
  top: 0;
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: $headHeight;
  .username{
    padding-left: 50px;
    position: relative;
    color: #a0baec;
    cursor: pointer;
  }
  .user{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background: #e3f7ff;
    overflow: hidden;
    i{
      font-size: 30px;
      color: #4389df;
    }
  }
}
.header .menus {
  position: absolute;
  top: $headHeight;
  left: -5px;
  background: #fff;
  width: 180px;
  z-index: 1000;
  color: #000;
  padding: 10px 20px;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
  display: none;
}
.header .menus.show {
  display: block;
}

.header .menus .password {
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  i{
    margin-right: 10px;
    color: #2197ff;
    font-size: 15px;
  }
}


.contain .main {
  height: calc(100% -  #{$headHeight});
}

.contain .side {
  height: 100%;
  background: #002664;
  transition: 0.2s width ease-in-out;
  .el-scrollbar__wrap{
    overflow-x: hidden !important;
  }

  .iconfont{
    margin-right: 6px;
    font-size: 18px;
    margin-left: 5px;
  }
}

.contain .side > ul {
  height: 100%;
}

.mainbox {
  padding: 0 !important;
}

.mainbox .router-tab-header {
  height: 46px !important;
}

.el-menu-item.is-active {
  color: #fff !important;
  background: #5084ff !important;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
</style>
