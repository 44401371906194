import request from '@/utils/request'


// 获取社区数据列表
export function shequList(params) {
  return request({
    url: '/levelcode/CommunityQulist',
    method: 'get',
    params:params
  })
}

// 获取可查看的社区
export function isShequ(params) {
    return request({
      url: '/sysmenu/getLevelcodeByUser',
      method: 'get',
      params:params
    })
  }

  // 增加社区
export function addShequ(params) {
    return request({
      url: '/levelcode/addshequ',
      method: 'get',
      params:params
    })
  }

  
  // 修改社区
export function updateShequ(params) {
    return request({
      url: '/levelcode/updateshequ',
      method: 'get',
      params:params
    })
  }

    // 删除社区
export function removeShequ(params) {
    return request({
      url: '/levelcode/remove',
      method: 'get',
      params:params
    })
  }